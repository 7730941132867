import React from 'react';
import { Icon } from '@springforcreators/propel-ui';
import './HeaderSearch.scss';

export const HeaderSearch = () => {
  return (
    <div className="header__search">
      <Icon name="Search" size={ 18 } />
    </div>
  );
};

export default HeaderSearch;
